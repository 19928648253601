import { Component, OnInit, HostListener, Output, EventEmitter, Input, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../../Services/generalservice.service'
import { CartService } from '../../../Services/cart.service'

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {
    @ViewChild('loader') loader: ElementRef | undefined;
    HomeURL:any
  arr: any;
    showCategories: boolean = false;
    Categories: any;
    loginDet: any;
    loginDetails: any;
    cartItems: any;
    @Output() someEvent = new EventEmitter<string>();
    cartItemsCount: any; category: any = [];
    token: any;
    constructor(public cartservice: CartService, private viewportScroller: ViewportScroller, private route: ActivatedRoute,
        public router: Router, public generalService: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.cartservice.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // Clear products after navigation is successful
                this.Categories = [];
            }
        });
  }


  ngOnInit(): void {
    this.GetCategories();
  }
  classApplied = false;
  //  router: any;
    selectedItem: any;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  listClick(val:any) {
    this.selectedItem = val;
  }

  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

    ngAfterViewInit() {
        // Ensure loader is defined before using it
        if (this.loader) {
            this.hideLoader(); // Initially hide the loader
        } else {
            console.error('Loader element not found.');
        }
    }

    // Methods to show and hide the loader
    showLoader() {
        if (this.loader) {
            this.loader.nativeElement.style.display = 'block';
        } else {
            console.error('Loader element not found.');
        }
    }

    hideLoader() {
        if (this.loader) {
            this.loader.nativeElement.style.display = 'none';
        } else {
            console.error('Loader element not found.');
        }
    }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
  Schools() {
    //this.router.navigate(['/Schools']);
  } home() {
    this.router.navigate(['/Home']);
  } about() {
      this.router.navigate(['/About']);
    } contact() {
        debugger
    this.router.navigate(['/Contact']);
  }
  Login() {
    this.router.navigate(['/Login']);
  }
    logout() {
      
        this.loginDetails = null;
        localStorage.removeItem("LoginDetails");
        //  localStorage.removeItem("cartItems");
        this.router.navigate(['/Login']);
    }

  Request() {
    this.router.navigate(['/Request']);
    }

    getProducts1(searchValue: string) {
        if (!searchValue) {
            this.category = null; // Clear the category if searchValue is empty
            return; // Do nothing if searchValue is empty
        }

        const formData = new FormData();
        formData.append('Param1', searchValue);

        const url = 'api/Azole/Products_Search';
        this.generalService.PostData(url, formData).then(
            (data) => {
              
                this.category = data;
            },
            (err) => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }
   

    GetCategories() {
      
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeURL + "api/Azole/Category_Crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => { 

                    this.Categories = data;
                
                },
                (err) => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    cartdedails() {
       
        if (!this.loginDetails) {
            this.generalService.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'You are not logged in. Please login', 'Warning');

            this.router.navigate(['/Login']);

        }
        else if (this.cartItems === undefined || !this.cartItems || this.cartItems.length === 0) {
            this.generalService.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'No Products in the Cart', '');
        } else {
            this.router.navigate(['/Cart']);
        }
    }

    countReturn() {


        this.cartservice.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

            this.cartItemsCount = res.length
            return this.cartItemsCount
        })
        return this.cartItemsCount
    }
    //navigatingToSearchedProduct(CategoryID: number) { 
    //    this.router.navigate(['/Products/' + CategoryID]);
    //}
    loading: boolean = false;

    

    navigatingToSearchedProduct(productName: string) {
        // Show the loader
        this.loading = true;

        // Clear the search results (category) immediately
        this.category = null;


        localStorage.setItem('CRMID', productName)
        const cleanedProductName = productName
            .replace(/[(),%+\-\s]/g, '') // Remove (, ), ,, %, +, -, and spaces

        // Navigate to the product page
        this.router.navigate(['/Product/' + cleanedProductName]).then(() => {
            // Reload the page
            window.location.reload();
        }).catch(error => {
            console.error('Navigation to product page failed:', error);
        }).finally(() => {
            // Hide the loader
            this.loading = false;
        });
    }
    // Methods to show and hide the loader
   



    navigatingToSearchedProducts(CategoryID: number, CategoryName: any) {
      
        this.router.navigate(['/Products'], { queryParams: { categoryID: CategoryID, CategoryName: CategoryName } });
       // const queryParams = this.route.snapshot.queryParams;
        localStorage.setItem("cat", CategoryName);

    }

    getProducts(searchValue: string) {
        debugger
       
        if (!searchValue) {
            this.category = null; // Clear the Categories array if searchValue is empty
            return; // Do nothing if searchValue is empty
        }

        const formData = new FormData();
        formData.append('Param1', searchValue);

        const url = 'api/Azole/Products_Search';
        this.generalService.PostData(url, formData).then(
            (data) => { 
                this.category = data;
                
            },
            (err) => {
                this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }

    getCategoryDetails() {
        if (!this.category || this.category.length === 0) {
            return 'No products matched'; // Display this message if no results are found
        }

        let details = '';
        this.category.forEach((item, index) => {
            details += `Product ${index + 1}: ${item.Products}\n`;
        });

        return details;
    }
}
