<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>

                </li>
                <li class="breadcrumb-item" style="color:white;">{{ getCategoryName(activeCategoryID) }} /</li>
                <li class="breadcrumb-item" style="color:white;">Products</li>
            </ul>
        </div>
    </nav><br />
    <div class="container">
        <div class="row">
            <div class="col-lg-10">

                <div class="input-group">
                    <input type="search" placeholder="Search by Chemical Name/ CAS No/ Catalog No/ Category" class="search" #searchInput (input)="getProducts(searchInput.value)" />
                </div>
                <div class="search-results-popup" *ngIf="searchInput.value && category !== null" style="    margin-top: -5%;">
                    <div class="" *ngIf="category && category.length > 0" style="cursor:pointer">
                        <div>
                            <ul class="list-group search-results-container">
                                <li class="list-group-item" *ngFor="let item of category"
                                    (click)="navigatingToSearchedProduct(item.Name)">{{ item.Products }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="no-results" *ngIf="category && category.length === 0">
                        No products matched
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-xs-6">
                <div class="row">
                    <div class="col-lg-6 col-6 col-xs-6">
                        <!--<div class="countt">{{ searchedProductsCount }} / {{ Counts.Prodctscount }}</div>-->
                    </div>
                    <div class="col-lg-6 col-6 col-xs-6 text-xs-end text-lg-right">
                        <ul>
                            <li (click)="isGridView = true" title="Grid View"> <i class="fa fa-th-large" style="cursor:pointer"></i>&nbsp;&nbsp;</li>
                            <li (click)="isGridView = false" title="List View"> <i class="fa fa-bars" style="cursor:pointer"></i> </li>
                        </ul>
                    </div>
                </div>

            </div>
            <!--<div class="col-lg-1 col-xs-6" (click)="isGridView = true" title="Grid View">
                <i class="fa fa-th-large" style="cursor:pointer"></i>
            </div>

            <div class="col-lg-1 col-xs-6" (click)="isGridView = false" title="List View">
                <i class="fa fa-bars" style="cursor:pointer"></i>
            </div>-->

            <div class="col-lg-3">
                <div class="vertical-pills">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item" *ngFor="let item of originalCategory">
                            <!-- Display the category name -->
                            <a class="nav-link"
                               [routerLink]="['/Products']"
                               [class.active]="activeCategoryID === item.CategoryID"
                               [queryParams]="{ categoryID: item.CategoryID }">
                                {{ item.CategoryName }} <!-- Category Name -->
                                <!-- Display the count of filtered products for this category -->
                                <!--<span *ngIf="getFilteredProductCount(item.CategoryID) !== 0">({{ getFilteredProductCount(item.CategoryID) }})</span>-->
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-9" routerLink="/Productdetails">
                <div class="row" *ngIf="isGridView">
                    <div class="col-lg-3 col-xs-6" style="cursor:pointer" *ngFor="let product of filteredProducts">
                        <div class="card" (click)="navigatingToSearchedProduct(product.Name)">
                            <div class="row justify-content-center align-items-center">
                                <div class="col-lg-8 text-center">
                                    <div class="hover01 column">
                                        <figure>
                                            <img [src]="product.Record_Image ? HomeUrl + product.Record_Image : 'assets/Images/Azole logo Logo_TM.svg'"
                                                 class="card_image"
                                                 alt="DB" />
                                        </figure>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div class="row">

                                <div class="col-lg-6 col-xs-6">
                                    <div class="cas">{{ product.CAS_No }}</div>
                                </div>
                                <div class="col-lg-6 col-xs-6">
                                    <div class="hsn">{{ product.HSN }}</div>
                                </div><p></p>
                                <div class="col-lg-12">
                                    <div class="chemical_name">{{ product.Name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="filteredProducts && filteredProducts.length > 0">

                    <table class="table" *ngIf="!isGridView">
                        <!-- Table view content -->
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Structure</th>
                                <th scope="col">Chemical Name</th>
                                <th scope="col">CAS No</th>
                                <th scope="col">HSN Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of filteredProducts" (click)="navigatingToSearchedProduct(product.Name)">
                                <td>
                                    <figure>
                                        <img [src]="product.Record_Image ? HomeUrl + product.Record_Image : 'assets/Images/Azole logo Logo_TM.svg'"
                                             class="card_image"
                                             alt="DB" />
                                    </figure>
                                    <!--<img [src]="product.Record_Image" />-->
                                </td>
                                <td>{{ product.Name }}</td>
                                <td>{{ product.CAS_No }}</td>
                                <td>{{ product.HSN }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
