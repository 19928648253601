import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service'
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    arr: any = [];
    category: any = [];
    loading: boolean;






    constructor(public router:Router,private viewportScroller: ViewportScroller, private generalservice: GeneralService,) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
       
    }
    getProducts(searchValue: string) {
        if (!searchValue) {
            this.category = null; // Clear the category if searchValue is empty
            return; // Do nothing if searchValue is empty
        }

        const formData = new FormData();
        formData.append('Param1', searchValue);

        const url = 'api/Azole/Products_Search';
        this.generalservice.PostData(url, formData).then(
            (data) => {
                 
                this.category = data;
            },
            (err) => {
                this.generalservice.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }
    getCategoryDetails() {
        if (!this.category || this.category.length === 0) {
            return 'No products matched'; // Display this message if no results are found
        }

        let details = '';
        this.category.forEach((item, index) => {
            details += `Product ${index + 1}: ${item.Products}\n`;
        });

        return details;
    }

    //navigatingToSearchedProduct(Productname: any) {
    //    debugger
    //    localStorage.removeItem('CRMID');
    //    this.router.navigate(['/Product/' + Productname]);
    //}


    navigatingToSearchedProduct(productName: string) {
        // Show the loader
        this.loading = true;

        // Clear the search results (category) immediately
        this.category = null;


        localStorage.setItem('CRMID', productName)
        const cleanedProductName = productName
            .replace(/[(),%+\-\s]/g, '') // Remove (, ), ,, %, +, -, and spaces

        // Navigate to the product page
        this.router.navigate(['/Product/' + cleanedProductName]).then(() => {
            // Reload the page
            window.location.reload();
        }).catch(error => {
            console.error('Navigation to product page failed:', error);
        }).finally(() => {
            // Hide the loader
            this.loading = false;
        });
    }
}
